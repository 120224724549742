.container {
    width: 600px;
    margin: auto;

    @media screen and (max-width: 600px) {
        width: auto;
    }
}

.ui-alert {
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 5px;
    background-color: #2d99e2;
    color: #fff;
    width: 100%;
    margin-bottom: 15px;
    box-sizing: border-box;
    overflow: hidden;

    &.append {
        margin-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &.hover {
        .row-base {
            ::before {
                position: absolute;
                display: block;
                content: "";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                pointer-events: none;
            }
        }
    }

    .row-base {
        position: relative;
        display: flex;
        align-items: center;


        .ico {
            opacity: .5;
        }

        .ico,
        .opt {
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            width: 40px;
            min-width: 40px;
            height: 40px;
        }

        .text {
            flex-grow: 1;
        }

        .sub,
        .text {
            padding: 8px 15px 8px 0;
        }

        .opt {
            cursor: pointer;
        }
    }
}

svg:not(:root).svg-inline--fa,
svg:not(:host).svg-inline--fa {
    overflow: visible;
    box-sizing: content-box;
}

.svg-inline--fa {
    display: var(--fa-display, inline-block);
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}

.ui-card {
    border-radius: 5px;
    box-shadow: 0 25px 35px 0 rgb(45 66 82 / 10%);
    transition: all .1s ease-in-out;
    position: relative;
    background-color: #fff;
    color: #0a131a;
    margin-bottom: 15px;
    box-sizing: border-box;
    overflow: hidden;

    .ui-card-specs {
        font-size: 12.5px;

        .row {
            display: flex;
            align-items: center;
            background-color: #ebf2f5;
            min-height: 40px;
            padding-left: 20px;
        }

        .col {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            color: #6d8291;
            font-weight: 600;
            padding: 12px 15px 12px 0;

            b {
                color: #2d4352;
            }

            span {
                margin-left: 10px;
            }

            svg {
                margin-right: 10px;
            }
        }

        .fx-analyze {
            .trim {
                border-radius: 5px;
                display: inline-block;
                width: 2px;
                height: 15px;
                background-color: #c4cfd6;
                margin-right: 5px;
            }

            b {
                margin-right: 5px;
            }
        }

        .flex {
            flex-grow: 1;
        }

        svg {
            font-size: 14px;
        }
    }
}

.ui-table-block,
.ui-table-info {
    width: 100%;
    padding: 0;
    margin: 0;
    border-spacing: 0;
    border-collapse: separate;
    overflow: hidden;
}

.ui-table-info {

    td,
    th {
        text-align: left;
        font-weight: 600;
    }
}

.ui-table-block {
    border-spacing: 0 5px;
    margin-top: -5px;
    margin-bottom: 15px;
    color: #6d8291;

    tr {
        position: relative;

        &.header-info {

            td,
            th {
                -moz-user-select: -moz-none;
                -webkit-user-select: none;
                user-select: none;
                padding-top: 5px;
                padding-bottom: 0;
                background-color: transparent !important;
                white-space: nowrap;
            }
        }
    }

    td,
    th {
        text-align: left;
        font-weight: 600;
    }

    td,
    th {
        position: relative;
        padding: 12px 15px 12px 0;
        font-size: 12.5px;
        line-height: 16px;
        background-color: #fff;
    }

    td:first-child,
    th:first-child {
        border-top-left-radius: 5px;
        padding-left: 15px;
    }

    &.gray {
        td {
            background-color: #ebf2f5;
        }
    }
}

.fx-card-list {
    .fx-list-header {
        padding-bottom: 20px;
        padding-top: 15px;
        align-items: flex-start;

        .text {
            padding-top: 5px;
            padding-bottom: 0;
            min-height: inherit;
        }

        .title {
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 4px;
        }
    }

    .ui-card-header {
        display: flex;
        align-items: center;
        padding: 0 10px 0 0;

        .text {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-grow: 1;
            padding: 10px 0 10px 15px;
            min-height: 60px;
            box-sizing: border-box;
        }

        .title {
            transition: all .2s ease-in-out;
            font-size: 18px;
            line-height: 24px;
            font-weight: 700;
            word-wrap: break-word;
            overflow-wrap: break-word;
            -webkit-hyphens: auto;
            hyphens: auto;

            span {
                display: inline-block;
            }
        }
    }

    table {
        &.fx-break {
            position: relative;

            td,
            th {
                position: relative;
                word-wrap: break-word;
                overflow-wrap: break-word;
                -webkit-hyphens: auto;
                hyphens: auto;
                overflow: hidden;
            }

            td {
                padding-bottom: 0;
            }

            .fx-break-cont {
                max-height: 100px;
                overflow: hidden;
                padding-bottom: 10px;

                &::before {
                    position: absolute;
                    display: block;
                    content: "";
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 15px;
                }
            }
        }
    }

    .ui-table-block {
        color: #2d4352;
        margin-bottom: 0;

        td {
            font-size: 14px;

            &.td-opt {
                transition: padding .2s ease-in-out;
                cursor: pointer;

                &:hover {
                    padding-left: 15px;
                    padding-right: 0;
                    color: #fff !important;

                    &.color-primary {
                        background-color: #1edd70 !important;
                    }

                    &.color-negative {
                        background-color: rgb(255, 80, 80) !important;
                    }
                }
            }
        }

        .gray {
            background-color: #ebf2f5;
        }
    }

    .ui-table-block {
        tr.header-info {
            th {
                color: #c4cfd6;
            }

            td:first-child,
            th:first-child {
                padding-left: 0;
            }
        }
    }
}
.ui-table-block {

    td,
    th {

        position: relative;
        padding: 12px 15px 12px 0;
        font-size: 12.5px;
        line-height: 16px;
        background-color: #fff;

        &.fx-bold {
            font-weight: 700;
        }

        &:first-child,
        &:first-child {
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
            padding-left: 15px;
        }

        &:last-child,
        &:last-child {
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
        }

        &.ico,
        &.opt {
            position: relative;
            padding: 0;
            width: 40px;
            min-width: 40px;
            max-width: 40px;
            height: 40px;
            color: #6d8291;
            text-align: center;
        }
    }
}

.unselect {
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    user-select: none;
}

.ui-space {
    border-radius: 5px;
    transition: all .1s ease-in-out;
    box-shadow: 0 5px 25px 2px rgb(227 7 22 / 75%);
    width: 30px;
    height: 3px;
    margin-top: 4px;
    background-color: #e30716;
}

.space-15 {
    margin-bottom: 15px !important;
}

.ui-analyze-bar {
    border-radius: 5px;
    position: relative;
    height: 5px;
    width: 50px;
    background-color: rgba(0, 20, 40, .12);
    margin-right: 5px;
    overflow: hidden;

    div {
        border-radius: 5px;
        transition: all .2s ease-in-out;
        position: absolute;
        height: 5px;
        top: 0;
        left: 0;
        background-color: #1edd70;
    }
}

.editor {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;

    p {
        font-size: 14px;
    }

    blockquote,
    h2,
    h3,
    p,
    ul {
        margin: 0;
        padding: 0;
        margin-bottom: 15px;
    }
}

.ui-editor-readonly {
    padding: 0 15px;
    margin-bottom: 15px;
}

.ProseMirror {
    word-wrap: break-word;
    white-space: pre-wrap;
    white-space: break-spaces;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
    font-feature-settings: "liga" 0;
    position: relative;
}

.ui-card,
.ui-card-cont {
    position: relative;
}

.ui-card-cont {
    padding: 0 15px 0 15px;
}

.fx-table-scroll {
    position: relative;
    width: 100%;
    overflow: hidden;

    .fx-table-scroll-cont .fx-table-scroll-left .fx-table-scroll-right {
        position: absolute;
        top: 0;
    }

    .fx-table-scroll-cont {
        position: relative;
        width: 100%;
        height: 100%;
        scroll-behavior: smooth;
        overflow-x: auto;
        box-sizing: border-box;
    }


}

.color-primary {
    color: #1edd70 !important;
}
.color-negative {
    color: rgb(227, 7, 22) !important;
}

.ui-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(16, 31, 41, .9);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: 800;
    overflow-y: auto;
}

.color-info {
    color: #2d99e2 !important;
}

.label-row {
    display: flex;

    .col {
        padding-right: 5px;

        &:last-child {
            padding-right: 0;
        }

        &:first-child {
            flex-grow: 1;
        }
    }
}

.label-row,
label {
    display: block;
    color: #6d8291;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 2px;
}

.fx-insert-setup {
    .ui-window-wrap {
        max-width: 400px!important;

        .window-cont {
            .label-row {
                background-color: #ebf2f5;
                margin-bottom: 0;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                padding: 4px 10px;
                padding-bottom: 5px;
                margin-bottom: -3px;
                font-size: 12.5px;
            }
        }

        .fx-box-input {
            border-radius: 5px;
            background-color: #ebf2f5;
            padding: 12px 15px 12px 15px;
            line-height: 16px;
            margin-bottom: 10px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            font-weight: 600;
        }
    }
}

.ui-tab {
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    height: 50px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 0 0 35px 0 rgb(45 66 82 / 15%);

    .ui-tab-wrap {
        position: relative;
        overflow-x: auto;
    }

    .ui-tab-item {
        justify-content: center;
        box-sizing: border-box;
        transition: all .1s ease-in-out;
        font-size: 14px;
        padding-right: 30px;
        font-weight: 600;
        color: #6d8291;
        word-break: keep-all;
        white-space: nowrap;
        cursor: pointer;

        &:last-child {
            padding-right: 0;
        }
    }

    .ui-tab-item,
    .ui-tab-wrap {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .window-wrap {
        background-color: #dce5ea;
    }

    .window-cont {
        padding: 30px 20px 20px 20px;
    }

    &.dark {
        background-color: #101f29;
    }
}

.fx-user-select {
    color: #6d8291;
    padding-bottom: 10px;

    .wrap-input {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 5px;
        margin-bottom: 10px;

        .name {
            padding: 12px 15px 12px 15px;
            line-height: 18px;
            color: #2d4352;
        }
    }

    .col {
        .label {
            color: #2d99e2;
            font-size: 14px;
            line-height: 14px;
            padding-bottom: 5px;
        }

        .input {
            border-radius: 5px;
            display: flex;
            height: 40px;
            background-color: #fff;

            &.self {
                background-color: #c4cfd6;
            }

            .name {
                padding-left: 15px;
                font-weight: 600;
            }
        }
    }
}

.ui-window {
    .ui-window-wrap {
        border-radius: 5px;
        position: relative;
        display: block;
        margin: 0 auto;
        margin-top: 100px;
        margin-bottom: 50px;
        width: 100%;
        max-width: 580px;
        background-color: #0a131a;
        cursor: default;
        overflow: hidden;

        @media screen and (max-width: 600px) {
            margin: 0;
            min-height: 100%;
            height: 100%;
            max-width: 100%!important;
            border-radius: 0;
        }
    }

    .window-opt {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        padding: 10px;
        box-sizing: border-box;
        z-index: 1;

        .opt {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            transition: all .2s ease-in-out;
            position: relative;
            width: 40px;
            height: 40px;
            margin-left: 5px;
            color: #6d8291;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
                background-color: #1c2f3d !important;
                color: #fff;
            }
        }
    }

    .window-header {
        padding: 50px 20px 30px 20px;
        background-color: #0a131a;

        .title-large {
            color: #fff;
            margin: 0;
        }
    }

    .window-wrap {
        background-color: #dce5ea;

        @media screen and (max-width: 600px) {
            height: 100%;
        }
    }

    .window-tabs {
        background-color: #101f29;
        padding: 0 20px;
    }

    .window-cont {
        padding: 30px 20px 20px 20px;
    }

    .window-footer {
        padding: 0 20px 10px 20px;
    }

    .fx-box-input {
        border-top: 1px solid #dce5ea;
    }

    .label {
        font-weight: 600;
    }
}

.title-large {
    font-size: 28px;
}

.btn-primary,
.btn-success {
    background-color: #1edd70;
    box-shadow: 0 5px 25px 0 rgb(30 221 112 / 35%);
}
.btn-negative {
    background-color: rgb(227, 7, 22);
    box-shadow: 0 5px 25px 0 rgb(227, 7, 22 / 35%);
}

button,
input,
select,
textarea {
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.btn,
button {
    border-radius: 5px;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    user-select: none;
    background-color: #1c2f3d;
    box-shadow: 0 5px 25px 0 rgb(28 47 61 / 35%);
    transition: all .2s ease-in-out;
    position: relative;
    display: inline-block;
    font-family: LUK_PIX;
    font-size: 13px;
    line-height: 16px;
    font-weight: 600;
    padding: 12px 20px;
    border: 0 solid transparent;
    color: #fff !important;
    margin-right: 5px;
    margin-bottom: 5px;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    outline: 0 !important;
    box-sizing: border-box;

    svg {
        position: relative;
        padding: 0!important;
        margin-left: -5px;
        margin-right: 10px;
        color: inherit;
        opacity: .75;
    }

    &:disabled {
        background-color: #c4cfd6;
        box-shadow: none;
        color: gray!important;
    }
}

.title-extra,
.title-large {
    line-height: 42px;
    font-weight: 700;
}

.fx-wrap {
    will-change: height, opacity;
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: 1000px;
}

.vote {
    font-size: 12px;
    font-weight: normal;
    padding-left: 15px;

    &:last-child {
        padding-bottom: 10px;
    }
}
.bold {
    font-weight: 700;
}
input {
    &.createUserInput {
        height: 100%;
        width: 320px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        border-radius: 10px;
        background-color: #c4cfd6;
        border: none;
        font-size: 20px;
        padding: 10px 20px;
        margin-top: 10px;
    }
}
.winner {
    color: #1edd70;
}
.loser {
    text-decoration: line-through;
}
.infoText {
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
    color: white;
    font-size: 14px;
}

.divider {
    margin: auto;
    width: 600px;
    background-color: white;
    padding: 10px 0;
    margin-bottom: 10px;
    border-radius: 5px;
    font-weight: 500;
    color: #0a131a;

    @media screen and (max-width: 600px) {
        width: 100%;
    }

    span {
        padding: 20px;
    }
}
a {
    color: white;
}
.noScroll {
    overflow: hidden;
}